import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Backdrop Projections - Dynamic Scenic Projections';
  
  constructor(public gtag: Gtag, private router: Router) {
    gtag.pageview();
    (window as any).ngRouter = this.router;
  }
}
