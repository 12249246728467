import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor( private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("About Us - Backdrop Projections");
  }

}
