import { Component, OnInit } from "@angular/core";
interface FormModel {
  captcha?: string;
}
@Component({
  selector: "app-google-recaptcha",
  templateUrl: "./google-recaptcha.component.html",
  styleUrls: ["./google-recaptcha.component.css"],
})
export class GoogleRecaptchaComponent implements OnInit {
  myRecaptcha: boolean;
  checkCaptcha(captchaResponse: string) {
    this.myRecaptcha =
      captchaResponse && captchaResponse.length > 0 ? true : false;
  }
  public onError(errorDetails: any): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  constructor() {}
  public formModel: FormModel = {};
  ngOnInit() {}
}
