import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "payment-error",
  templateUrl: "./payment-error.component.html",
  styleUrls: ["./payment-error.component.css"],
})
export class PaymentErrorComponent implements OnInit {
  user_id: any = localStorage.getItem("currentUserId")
    ? localStorage.getItem("currentUserId")
    : sessionStorage.getItem("currentUserId");
  payment_status: any;
  query_error:string;

  constructor(
    private checkoutCart: DataService,
    
    private router: Router,
    public dialog: MatDialog,
    private titleService: Title
  ) {}

  ngOnInit() {
    let url = new URL(window.location.href); // error pushing from cart state
  this.query_error =url.searchParams.get("q");//getting error 
    this.checkoutCart.getPaymentStatus(this.user_id).subscribe((data: any) => {
      console.log(data,"data getting pushed");
      if (data.success.status.error_message !== null) {
        console.log(data,"data error inc");
      console.log(data,)
        this.payment_status = data.success.status.error_message;
      } else if (data.success.status.api_error_message !== null) {
        this.payment_status = data.success.status.api_error_message;
      } else {
        this.payment_status = "Unknown";
      }
      console.log(data.success.status.id);
      if (data.success.status.id !== null) {
        localStorage.setItem("last_payment_error", data.success.status.id);
      }
    });
  }
}
