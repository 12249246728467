import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-thank-you',
  templateUrl: './contact-thank-you.component.html',
  styleUrls: ['./contact-thank-you.component.css']
})
export class ContactThankYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
