import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  myRecaptcha: boolean;
  email: any = '';
  password: any = '';
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  name: any;
  last_name: any;
  tel: any;
  repeatPassword: any;
  remember_me: any;
  selectedCountry: any = 'USA';
  selectedState: any = '';
  pageLikes: any;
  cartValues: any;
  termId: any = localStorage.getItem('term_type_id');

  constructor(private register: DataService, private router: Router,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Sign Up - Backdrop Projections");
  }

  registerUser({ value, valid }: { value: any, valid: boolean }) {
    this.showSpinner = true;
    if (!valid) {
      console.log('not valid');
    } else {
      this.register.getRegisterDetails(value['name'], value['last_name'], value['tel'],
        value['email'], value['country'], value['state'], value['password']
        , value['remember_me']).subscribe(
          (data: any) => {
            console.log(data);
            if (data.success) {
              // alert('Welcome')
              setTimeout(() => {
                this.showSpinner = false;
                this.showSuccess = true;
              }, 5000);

              if (data.success.remember_token && value['remember_me'] === true) {
                localStorage.setItem('token', data.success.token);
                localStorage.setItem('currentUserId', data.success.user.id);
                localStorage.setItem('currentUserName', data.success.user.name);
                localStorage.setItem('remember_me', data.success.remember_token);
                // this.router.navigate(['/']);
              } else {
                sessionStorage.setItem('token', data.success.token);
                sessionStorage.setItem('currentUserId', data.success.user.id);
                sessionStorage.setItem('currentUserName', data.success.user.name);
                // this.router.navigate(['/']);
              }
              if (sessionStorage.getItem('cart') === null || sessionStorage.getItem('favorites') === null) {
                this.router.navigate(['/signupSuccessful']);
              }

          // Add to favorites
          if (sessionStorage.getItem('favorites') !== null) {
            if (this.termId !== 'showPackages') {
          setTimeout(() => {
              this.register.addToFavorites(data.success.user.id, sessionStorage.getItem('favoriteImageId')).subscribe(
                (addToFavorites: any) => {
                  if (addToFavorites.success) {
                    console.log(addToFavorites.success);
                    this.pageLikes += 1;
                    sessionStorage.removeItem('favorites');
                    sessionStorage.removeItem('favoriteImageId');
                    this.router.navigate(['/favorites']);
                  }
                },
                (error: any) => {
                  console.log(error);
                  this.router.navigate(['/error']);
                }
              );
          }, 500);
        }
      }


      if (sessionStorage.getItem('favorites') !== null) {
        if (this.termId === 'showPackages') {

          this.register.addShowPackageToFavorites(data.success.user.id, sessionStorage.getItem('favoriteImageId'))
          .subscribe(
            (addToFavorites: any) => {
              if (addToFavorites.success) {
                console.log(addToFavorites.success);
                this.pageLikes += 1;
                sessionStorage.removeItem('favorites');
                sessionStorage.removeItem('favoriteImageId');
                this.router.navigate(['/favorites']);
              }
            },
            (error: any) => {
              console.log(error);
              this.router.navigate(['/error']);
            }
          );
        }

        }
              // this.router.navigate(['/']);
              // Add to cart
              setTimeout(() => {
                if (sessionStorage.getItem('cart') !== null) {
                  if (this.termId !== 'showPackages') {
                  this.register.addToCart(data.success.user.id, sessionStorage.getItem('cartImageId'),
                  sessionStorage.getItem('cartImageStart'), sessionStorage.getItem('cartImageEnd'),
                  Number(sessionStorage.getItem('cartImageTotal')), sessionStorage.getItem('cartImageName')).subscribe(
                    (cart: any) => {
                      if (cart.success) {
                        console.log(cart.success);
                        this.cartValues += 1;
                      }
                    },
                    (error: any) => {
                      console.log(error);
                      this.router.navigate(['/error']);
                    }
                  );
             

              } else {
                this.register.addPackageToCart(data.success.user.id, sessionStorage.getItem('cartImageId'),
                sessionStorage.getItem('cartImageStart'), sessionStorage.getItem('cartImageEnd'),
                Number(sessionStorage.getItem('cartImageTotal')), sessionStorage.getItem('cartImageName')).subscribe(
                  (cartPackage: any) => {
                    if (cartPackage.success) {
                      console.log(cartPackage.success);
                      this.cartValues += 1;
                    }
                  },
                  (error: any) => {
                    console.log(error);
                    this.router.navigate(['/error']);
                  }
                );

              }
              // this.router.navigate(['/cart']);

            }
              }, 2000);

              if (sessionStorage.getItem('cart') !== null) {
              setTimeout(() => {
              this.router.navigate(['/cart']);
              sessionStorage.removeItem('cartImageId');
              sessionStorage.removeItem('cartImageName');
              sessionStorage.removeItem('cartImageTotal');
              sessionStorage.removeItem('cartImageStart');
              sessionStorage.removeItem('cartImageEnd');
              sessionStorage.removeItem('cart');
            }, 5000);
          }

            }

          },
          (error: any) => {
            console.log(error);
            if (error.status == 500) {
              this.router.navigate(['/error']);
            }
            this.errorMessage = error.error.message;
            for (const key in this.errorMessage) {
              if (this.errorMessage.hasOwnProperty(key)) {
                console.log(this.errorMessage[key]);
              }
            }
            setTimeout(() => {
              this.showSpinner = false;
            }, 1000);
            setTimeout(() => {
              this.showError = true;
            }, 1000);
            setTimeout(() => {
              this.showError = false;
            }, 5000);
          }

        );
    }
  }

}

