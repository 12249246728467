import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../../services/data.service";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import swal from "sweetalert2";
import { SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HandPaintedBackdropsComponent } from "../hand-painted-backdrops/hand-painted-backdrops.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  // @ViewChild("success", { static: false })
  // @ViewChild("success", { static: false }) private successSwal: SwalComponent;
  @ViewChild("signup") signupModal!: SwalComponent;

  isLoggedIn: boolean;
  userId: any = localStorage.getItem("currentUserId")
    ? localStorage.getItem("currentUserId")
    : sessionStorage.getItem("currentUserId");
  userName: any = localStorage.getItem("currentUserName")
    ? localStorage.getItem("currentUserName")
    : sessionStorage.getItem("currentUserName");
  text: any;
  type: any;
  title: any;
  fname: any = "";
  lname: any = "";
  email: any = "";
  date = new Date();

  constructor(
    private footer: DataService,
    private router: Router,
    public dialog: MatDialog,
    public readonly swalTargets: SwalPortalTargets
  ) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(HandPaintedBackdropsComponent, {
      width: "1024px",
      height: "600px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });

    document.getElementById("close").onclick = function () {
      dialogRef.close();
    };
  }

  ngOnInit() {
    if (this.userId) {
      this.isLoggedIn = true;
    }
  }
  openSignupModal(): void {
    this.signupModal.fire();
  }

  getCopyrightDate(): number {
    let getYear = this.date.getFullYear();

    return getYear;
  }
  public checkDetails(): void {
    swal.disableButtons();
    const pattern = new RegExp(
      "[-a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[-a-zA-Z0-9]{2,}"
    );
    setTimeout(() => {
      if (
        (<HTMLInputElement>document.getElementById("first_name")).value !==
          "" &&
        (<HTMLInputElement>document.getElementById("last_name")).value !== "" &&
        pattern.test((<HTMLInputElement>document.getElementById("email")).value)
      ) {
        swal.getConfirmButton();
      }
    }, 500);
  }

  public add($event, successSwal, firstName, lastName, email): void {
    this.footer
      .addToNewsletter(
        this.userId,
        this.userName ? this.userName : firstName,
        lastName,
        email
      )
      .subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.text = "You successfully signed up to our newsletter";
            this.type = "success";
            this.title = "Success";
            setTimeout(() => {
              successSwal.fire();
            }, 1000);
          }
        },
        (error: any) => {
          console.log(error);
          this.text = "You already signed up for our newsletter";
          this.type = "error";
          this.title = "Already signed up";
          setTimeout(() => {
            successSwal.fire();
          }, 1000);
        }
      );
  }

  // public open($event, signup) {
  //   signup.show();
  // }
}
