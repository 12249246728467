import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-create-backdrop',
  templateUrl: './create-backdrop.component.html',
  styleUrls: ['./create-backdrop.component.css']
})
export class CreateBackdropComponent implements OnInit {

  myRecaptcha: boolean;
  password: any = '';
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  url: any;
  first_name: any;
  last_name: any;
  email: any;
  phone: any;
  company_name: any;
  date_needed: any;
  image: any;
  comments: any;
  minDate = moment().format();
  user_id: any = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');
  userDetails: any;
  isLoggedIn = false;
  fileName: any = 'No File Chosen';
  startDate = new Date(1990, 0, 1);

  constructor(private backdrop: DataService, private router: Router , private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Create Your Own - Backdrop Projections");
    if (this.user_id != null) {
      this.isLoggedIn = true;
      this.backdrop.getUserDetails(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.userDetails = data.success.user;
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      this.fileName =  event.target.files[0].name;

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  createBackdrop({ value, valid }: { value: any, valid: boolean }) {
    this.showSpinner = true;
    if (!valid) {
      console.log('not valid');
    } else {
      this.backdrop.createBackdrop(value['first_name'] , value['last_name'] , value['email'] , value['company_name']
       , value['date_needed'] , this.url , value['comments'] , value['phone']).subscribe(
        (data: any) => {
          console.log(data);
          if (data.success) {
            // alert('Welcome')
            setTimeout(() => {
              this.showSpinner = false;
              this.router.navigate(['/formSubmitted']);
            }, 2000);
          }

        },
        (error: any) => {
          console.log(error);
          if (error.status == 500) {
            this.router.navigate(['/error']);
          }
          this.errorMessage = error.error.message;
          for (const key in this.errorMessage) {
            if (this.errorMessage.hasOwnProperty(key)) {
                console.log(this.errorMessage[key]);
            }
        }
          setTimeout(() => {
            this.showSpinner = false;
          }, 1000);
          setTimeout(() => {
          this.showError = true;
        }, 1000);
          setTimeout(() => {
            this.showError = false;
          }, 5000);
        }

      );
    }
  }

}
