import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
interface FormModel {
  captcha?: string;
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  myRecaptcha: boolean;
  email: any = "";
  password: any = "";
  showError: boolean;
  errorMessage: any = "";
  remember_me: any;
  cartValues: any;
  termId: any = localStorage.getItem("term_type_id");
  packageInCart: boolean;
  inCart: boolean;
  favoriteImages: any;
  favoritePackages: any;
  packageId: any;
  liked = false;
  pageLikes: any;
  client: any;

  constructor(
    private login: DataService,
    private router: Router,
    private location: Location,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Sign Up - Backdrop Projections");
  }
  checkCaptcha(captchaResponse: string) {
    this.myRecaptcha =
      captchaResponse && captchaResponse.length > 0 ? true : false;
  }
  public onError(errorDetails: any): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  loginUser({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      console.log("not valid");
    } else {
      this.login
        .getLoginDetails(
          value["email"],
          value["password"],
          value["remember_me"]
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            if (data.success) {
              if (
                data.success.remember_token &&
                value["remember_me"] === true
              ) {
                localStorage.setItem("token", data.success.token);
                localStorage.setItem("currentUserId", data.success.user.id);
                localStorage.setItem("currentUserName", data.success.user.name);
                localStorage.setItem(
                  "remember_me",
                  data.success.remember_token
                );
                // Add to favorites
                if (sessionStorage.getItem("favorites")) {
                  this.login.getFavorites(data.success.user.id).subscribe(
                    (favorite: any) => {
                      if (favorite.success) {
                        console.log(data.success);
                        this.favoriteImages = favorite.success.favorites;
                        this.favoritePackages = favorite.success.packageID;
                        if (this.termId !== "showPackages") {
                          if (this.favoriteImages.length == 0) {
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addToFavorites(
                                    data.success.user.id,
                                    sessionStorage.getItem("favoriteImageId")
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }

                          for (let i = 0; i < this.favoriteImages.length; ++i) {
                            console.log(this.favoriteImages);
                            const imageLiked = this.favoriteImages[i];
                            if (
                              imageLiked.id ==
                              Number(sessionStorage.getItem("favoriteImageId"))
                            ) {
                              this.liked = true;
                              console.log("already liked");
                              break;
                            }
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addToFavorites(
                                    data.success.user.id,
                                    sessionStorage.getItem("favoriteImageId")
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }
                        }
                        if (this.termId === "showPackages") {
                          for (
                            let i = 0;
                            i < this.favoritePackages.length;
                            ++i
                          ) {
                            console.log(this.favoritePackages);
                            const imageLiked = this.favoritePackages[i];
                            if (
                              imageLiked ==
                              Number(sessionStorage.getItem("favoriteImageId"))
                            ) {
                              this.liked = true;
                              console.log("already liked");
                              break;
                            }
                          }

                          if (this.favoritePackages.length == 0) {
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addShowPackageToFavorites(
                                    data.success.user.id,
                                    Number(
                                      sessionStorage.getItem("favoriteImageId")
                                    )
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }

                          for (
                            let i = 0;
                            i < this.favoritePackages.length;
                            ++i
                          ) {
                            const packageLiked = this.favoritePackages[i];
                            if (packageLiked == this.packageId) {
                              this.liked = true;
                              console.log("already liked");
                              break;
                            }
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addShowPackageToFavorites(
                                    data.success.user.id,
                                    sessionStorage.getItem("favoriteImageId")
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }
                        }
                        this.pageLikes =
                          this.favoriteImages.length +
                          this.favoritePackages.length;
                        if (this.liked === true) {
                          this.pageLikes =
                            this.favoriteImages.length +
                            this.favoritePackages.length;
                        }
                      }
                    },
                    (error: any) => {
                      console.log(error);
                      this.router.navigate(["/error"]);
                      sessionStorage.removeItem("favorites");
                      sessionStorage.removeItem("favoriteImageId");
                    }
                  );
                }

                // Add to cart
                if (sessionStorage.getItem("cart") !== null) {
                  if (this.termId !== "showPackages") {
                    this.login
                      .checkCart(
                        Number(sessionStorage.getItem("cartImageId")),
                        data.success.user.id
                      )
                      .subscribe(
                        (cart: any) => {
                          if (cart.success) {
                            console.log(cart.success);
                            if (cart.success.cartItem.length === 0) {
                              this.inCart = false;
                            } else {
                              this.inCart = true;
                            }
                          }
                        },
                        (error: any) => {
                          console.log(error);
                          this.inCart = false;
                          this.router.navigate(["/error"]);
                        }
                      );
                    setTimeout(() => {
                      if (!this.inCart) {
                        this.login
                          .addToCart(
                            data.success.user.id,
                            sessionStorage.getItem("cartImageId"),
                            sessionStorage.getItem("cartImageStart"),
                            sessionStorage.getItem("cartImageEnd"),
                            Number(sessionStorage.getItem("cartImageTotal")),
                            sessionStorage.getItem("cartImageName")
                          )
                          .subscribe(
                            (cart: any) => {
                              if (cart.success) {
                                console.log(cart.success);
                                this.inCart = true;
                                this.router.navigate(["/cart"]);
                                this.cartValues += 1;
                              }
                            },
                            (error: any) => {
                              console.log(error);
                              this.router.navigate(["/error"]);
                            }
                          );
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                      } else {
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                        this.router.navigate(["/cart"]);
                      }
                    }, 1000);
                  } else {
                    this.login
                      .checkCartPackage(
                        Number(sessionStorage.getItem("cartImageId")),
                        data.success.user.id
                      )
                      .subscribe(
                        (cartPackage: any) => {
                          if (data.success) {
                            console.log(cartPackage.success);
                            if (cartPackage.success.cartItem.length === 0) {
                              this.packageInCart = false;
                            } else {
                              this.packageInCart = true;
                            }
                          }
                        },
                        (error: any) => {
                          console.log(error);
                          this.packageInCart = false;
                          this.router.navigate(["/error"]);
                        }
                      );
                    setTimeout(() => {
                      if (!this.packageInCart) {
                        this.login
                          .addPackageToCart(
                            data.success.user.id,
                            sessionStorage.getItem("cartImageId"),
                            sessionStorage.getItem("cartImageStart"),
                            sessionStorage.getItem("cartImageEnd"),
                            Number(sessionStorage.getItem("cartImageTotal")),
                            sessionStorage.getItem("cartImageName")
                          )
                          .subscribe(
                            (cartPackage: any) => {
                              if (cartPackage.success) {
                                console.log(cartPackage.success);
                                this.inCart = true;
                                this.router.navigate(["/cart"]);
                                this.cartValues += 1;
                              }
                            },
                            (error: any) => {
                              console.log(error);
                              this.router.navigate(["/error"]);
                            }
                          );
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                      } else {
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                        this.router.navigate(["/cart"]);
                      }
                    }, 1000);
                  }
                }
                this.router.navigate(["/"]);
              } else {
                sessionStorage.setItem("token", data.success.token);
                sessionStorage.setItem("currentUserId", data.success.user.id);
                sessionStorage.setItem(
                  "currentUserName",
                  data.success.user.name
                );

                // Add to favorites
                if (sessionStorage.getItem("favorites")) {
                  this.login.getFavorites(data.success.user.id).subscribe(
                    (favorite: any) => {
                      if (favorite.success) {
                        console.log(data.success);
                        this.favoriteImages = favorite.success.favorites;
                        this.favoritePackages = favorite.success.packageID;
                        if (this.termId !== "showPackages") {
                          if (this.favoriteImages.length == 0) {
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addToFavorites(
                                    data.success.user.id,
                                    sessionStorage.getItem("favoriteImageId")
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }

                          for (let i = 0; i < this.favoriteImages.length; ++i) {
                            console.log(this.favoriteImages);
                            const imageLiked = this.favoriteImages[i];
                            if (
                              imageLiked.id ==
                              Number(sessionStorage.getItem("favoriteImageId"))
                            ) {
                              this.liked = true;
                              console.log("already liked");

                              break;
                            }
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addToFavorites(
                                    data.success.user.id,
                                    sessionStorage.getItem("favoriteImageId")
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }
                        }
                        if (this.termId === "showPackages") {
                          for (
                            let i = 0;
                            i < this.favoritePackages.length;
                            ++i
                          ) {
                            console.log(this.favoritePackages);
                            const imageLiked = this.favoritePackages[i];
                            if (
                              imageLiked ==
                              Number(sessionStorage.getItem("favoriteImageId"))
                            ) {
                              this.liked = true;
                              console.log("already liked");
                              break;
                            }
                          }

                          if (this.favoritePackages.length == 0) {
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addShowPackageToFavorites(
                                    data.success.user.id,
                                    Number(
                                      sessionStorage.getItem("favoriteImageId")
                                    )
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }

                          for (
                            let i = 0;
                            i < this.favoritePackages.length;
                            ++i
                          ) {
                            const packageLiked = this.favoritePackages[i];
                            if (packageLiked == this.packageId) {
                              this.liked = true;
                              console.log("already liked");
                              break;
                            }
                            setTimeout(() => {
                              if (this.liked !== true) {
                                this.login
                                  .addShowPackageToFavorites(
                                    data.success.user.id,
                                    Number(
                                      sessionStorage.getItem("favoriteImageId")
                                    )
                                  )
                                  .subscribe(
                                    (addToFavorites: any) => {
                                      if (addToFavorites.success) {
                                        console.log(addToFavorites.success);
                                        this.pageLikes += 1;
                                        sessionStorage.removeItem("favorites");
                                        sessionStorage.removeItem(
                                          "favoriteImageId"
                                        );
                                        this.router.navigate(["/favorites"]);
                                      }
                                    },
                                    (error: any) => {
                                      console.log(error);
                                      this.router.navigate(["/error"]);
                                    }
                                  );
                              }
                            }, 500);
                          }
                        }
                        this.pageLikes =
                          this.favoriteImages.length +
                          this.favoritePackages.length;
                        if (this.liked === true) {
                          this.pageLikes =
                            this.favoriteImages.length +
                            this.favoritePackages.length;
                        }
                      }
                    },
                    (error: any) => {
                      console.log(error);
                      this.router.navigate(["/error"]);
                      sessionStorage.removeItem("favorites");
                      sessionStorage.removeItem("favoriteImageId");
                    }
                  );
                }

                // Add to cart
                if (sessionStorage.getItem("cart") !== null) {
                  if (this.termId !== "showPackages") {
                    this.login
                      .checkCart(
                        Number(sessionStorage.getItem("cartImageId")),
                        data.success.user.id
                      )
                      .subscribe(
                        (cart: any) => {
                          if (cart.success) {
                            console.log(cart.success);
                            if (cart.success.cartItem.length === 0) {
                              this.inCart = false;
                            } else {
                              this.inCart = true;
                            }
                          }
                        },
                        (error: any) => {
                          console.log(error);
                          this.inCart = false;
                          this.router.navigate(["/error"]);
                        }
                      );
                    setTimeout(() => {
                      if (!this.inCart) {
                        this.login
                          .addToCart(
                            data.success.user.id,
                            sessionStorage.getItem("cartImageId"),
                            sessionStorage.getItem("cartImageStart"),
                            sessionStorage.getItem("cartImageEnd"),
                            Number(sessionStorage.getItem("cartImageTotal")),
                            sessionStorage.getItem("cartImageName")
                          )
                          .subscribe(
                            (cart: any) => {
                              if (cart.success) {
                                console.log(cart.success);
                                this.inCart = true;
                                this.router.navigate(["/cart"]);
                                this.cartValues += 1;
                              }
                            },
                            (error: any) => {
                              console.log(error);
                              this.router.navigate(["/error"]);
                            }
                          );
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                      } else {
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                        this.router.navigate(["/cart"]);
                      }
                    }, 1000);
                  } else {
                    this.login
                      .checkCartPackage(
                        Number(sessionStorage.getItem("cartImageId")),
                        data.success.user.id
                      )
                      .subscribe(
                        (cartPackage: any) => {
                          if (data.success) {
                            console.log(cartPackage.success);
                            if (cartPackage.success.cartItem.length === 0) {
                              this.packageInCart = false;
                            } else {
                              this.packageInCart = true;
                            }
                          }
                        },
                        (error: any) => {
                          console.log(error);
                          this.packageInCart = false;
                          this.router.navigate(["/error"]);
                        }
                      );
                    setTimeout(() => {
                      if (!this.packageInCart) {
                        this.login
                          .addPackageToCart(
                            data.success.user.id,
                            sessionStorage.getItem("cartImageId"),
                            sessionStorage.getItem("cartImageStart"),
                            sessionStorage.getItem("cartImageEnd"),
                            Number(sessionStorage.getItem("cartImageTotal")),
                            sessionStorage.getItem("cartImageName")
                          )
                          .subscribe(
                            (cartPackage: any) => {
                              if (cartPackage.success) {
                                console.log(cartPackage.success);
                                this.inCart = true;
                                this.router.navigate(["/cart"]);
                                this.cartValues += 1;
                              }
                            },
                            (error: any) => {
                              console.log(error);
                              this.router.navigate(["/error"]);
                            }
                          );
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                      } else {
                        sessionStorage.removeItem("cartImageId");
                        sessionStorage.removeItem("cartImageName");
                        sessionStorage.removeItem("cartImageTotal");
                        sessionStorage.removeItem("cartImageStart");
                        sessionStorage.removeItem("cartImageEnd");
                        sessionStorage.removeItem("cart");
                        this.router.navigate(["/cart"]);
                      }
                    }, 1000);
                  }
                }
                // this.location.back();
                this.router.navigate(["/"]);
              }
            }
          },
          (error: any) => {
            console.log(error);
            if (error.status == 500) {
              this.router.navigate(["/error"]);
            }
            this.errorMessage = error.error.message;
            this.showError = true;
            setTimeout(() => {
              this.showError = false;
            }, 5000);
          }
        );
    }
  }
}
