import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../../services/data.service";
import { isDevMode } from "@angular/core";
import { local } from "../../../environments/local";
import { Title, Meta } from "@angular/platform-browser";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { InstallationGuideComponent } from "../installation-guide/installation-guide.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})

export class HomeComponent implements OnInit {
  
  recentImages: any;
  categoryTermID: any = 1;
  categoryImage: any;
  showThemeTermID: any = 2;
  showThemeImage: any;
  curatedShowPackageTermID: any = "showPackages";
  curatedShowPackageImage: any;
  homepageBanners: [
    {
      desktop: any;
      mobile: any;
    }
  ];
  path: any;
  mac_intel:any;
  mac_silicon:any;
  windows: any;
  slide: any;
  recentName: any;

  constructor(
    private router: Router,
    private home: DataService,
    private titleService: Title,
    public dialog: MatDialog,
    public meta: Meta
  ) {}

  openInstallationGuideDialog(): void {
    const dialogRef = this.dialog.open(InstallationGuideComponent, {
      width: "1024px",
      height: "600px",
    });

    document.getElementById("close").onclick = function () {
      dialogRef.close();
    };

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Welcome to Backdrop Projections");
    this.meta.updateTag({
      name: "description",
      content:
        "Browse projections to choose any image or animation. Any image may be animated upon request! Call 858-300-2100 or email us for more information.",
    });

    this.path = local.imageUrl + "/storage/";

    this.recentImages = JSON.parse(sessionStorage.getItem("recentImages"));
    this.recentName = JSON.parse(sessionStorage.getItem("recentImages"));
    if (this.recentImages) {
      for (let i = 0; i < this.recentImages.length; i++) {
        this.recentImages[i][0].name = this.recentImages[i][0]["name"].replace(
          /[' ']/g,
          "-"
        );
      }
    }

    this.home.getApp().subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.windows = data.success.windows;
          this.mac_intel = data.success.mac_intel;
          this.mac_silicon = data.success.mac_silicon;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(["/error"]);
      }
    );

    this.home.getBanners().subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.homepageBanners = data.success;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(["/error"]);
      }
    );

    // this.home.categoryImage(this.categoryTermID).subscribe(
    //   (data: any) => {
    //     if (data.success) {
    //       console.log(data.success);
    //       if (data.success.image.type === 'image') {
    //       this.categoryImage = data.success.image.img_path;
    //       } else {
    //         this.categoryImage = data.success.image.thumb_img_path;
    //       }
    //     }
    //   },
    //   (error: any) => {
    //     console.log(error);
    //   }
    // );

    // this.home.showThemeImage(this.showThemeTermID).subscribe(
    //   (data: any) => {
    //     if (data.success) {
    //       console.log(data.success);
    //       if (data.success.image.type === 'image') {
    //       this.showThemeImage = data.success.image.img_path;
    //       } else {
    //         this.showThemeImage = data.success.image.thumb_img_path;
    //       }
    //     }
    //   },
    //   (error: any) => {
    //     console.log(error);
    //   }
    // );

    // this.home.curatedShowPackageImage().subscribe(
    //   (data: any) => {
    //     if (data.success) {
    //       console.log(data.success);
    //       if (data.success.image.type === 'image') {
    //       this.curatedShowPackageImage = data.success.image.img_path;
    //       } else {
    //         this.curatedShowPackageImage = data.success.image.thumb_img_path;

    //       }
    //     }
    //   },
    //   (error: any) => {
    //     console.log(error);
    //   }
    // );
  }
  slides = [
    {
      title: "Lorem ipsum dolor sit 1",
      message:
        "In response to countless customer requests for digital projections, Backdrops Beautiful® is announcing Backdrop Projections! Browse our website to choose an image or animation you’d like to rent for projection (any image may easily be animated).\
    Feel free to download the Backdrop Projections Desktop App to test our projections before you buy!\
    ",
    },
  ];

  testimonials = [
    {
      author: "Levi",
      message:
        "Dear staff at Backdrops Beautiful, It was a real pleasure dealing with you guys, your product was great, you were true to your pledge to match the price of your competitors, and most importantly, the people I talked to were very helpful and went out of their way to please the customer. I’d like to point out specifically the woman that I talked to Friday the 22nd of February – I don’t remember who it was, she saw to it that the product would be delivered in time. Thank you very much! I do look forward to using you guys in the future.",
    },
    {
      author: "Vijay",
      message:
        "Backdrops Beautiful worked endlessly with our design staff to create a multidimensional backdrop depicting gorgeous fall scenery with various elements so lifelike you felt you could reach out and touch the foliage... An event company such as mine relies on vendors such as Backdrops Beautiful to supply upscale, timely products so that I can maintain my reputation as a company that delivers an outstanding end product.",
    },
    {
      author: "Levi",
      message:
        "Dear staff at Backdrops Beautiful, It was a real pleasure dealing with you guys, your product was great, you were true to your pledge to match the price of your competitors, and most importantly, the people I talked to were very helpful and went out of their way to please the customer. I’d like to point out specifically the woman that I talked to Friday the 22nd of February – I don’t remember who it was, she saw to it that the product would be delivered in time. Thank you very much! I do look forward to using you guys in the future.",
    },
    {
      author: "Vijay",
      message:
        "Backdrops Beautiful worked endlessly with our design staff to create a multidimensional backdrop depicting gorgeous fall scenery with various elements so lifelike you felt you could reach out and touch the foliage... An event company such as mine relies on vendors such as Backdrops Beautiful to supply upscale, timely products so that I can maintain my reputation as a company that delivers an outstanding end product.",
    },
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    // "autoplay": true,
    // "autoplaySpeed": 8000
  };
  testimonialSlideConfig = {
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  public loadImage(event, item) {
    localStorage.setItem("image_id", item);
    this.router.navigate(["/productDetails"]);
  }
  public browse(event, id) {
    localStorage.setItem("term_type_id", id);
    location.reload();
    this.router.navigate(["/categories"]);
  }
}
