import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, NavigationEnd , ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  constructor(private service: DataService , private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    setInterval(() => {
      this.service.getMaintenanceMode().subscribe(
       (data: any) => {
         if (data.success) {
           console.log(data.success);
           if (data.success.maintenanceModeStatus[0]['status'] === 'off') {
            location.replace('/')
           }
         }
       },
       (error: any) => {
         console.log(error);
         this.router.navigate(['/error']);
       }
     );
   }, 5000);
      }

}
