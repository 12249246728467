import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isDevMode } from '@angular/core';
import { local } from '../../../environments/local';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  images: any;
  q: any;
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  path: any;
  packages: any;
  packageImages: any;
  hasPackage: boolean;
  imageLinks: any;
  showPackagesLinks: any;

  constructor(private search: DataService, private router: Router, private route: ActivatedRoute,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Search - Backdrop Projections");


      this.path = local.imageUrl + '/storage';
      this.route.queryParams.subscribe(params =>
        this.q = params['q'])

    this.showSpinner = true;

    if (this.q !== null) {
      this.search.search(this.q).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            setTimeout(() => {
              this.showSpinner = false;
              this.images = data.success.images;
              this.imageLinks = data.success.imageLinks;
              for (let i = 0; i < this.imageLinks.length; i++) {
                this.imageLinks[i].name = this.imageLinks[i]['name'].replace(/[' ']/g, '-');
              }
              this.packages = data.success.showPackages;
              this.packageImages = data.success.showPackageImages;
              this.showPackagesLinks = data.success.showPackagesLinks;
              for (let i = 0; i < this.showPackagesLinks.length; i++) {
                this.showPackagesLinks[i].name = this.showPackagesLinks[i]['name'].replace(/[' ']/g, '-');
              }

            }, 1000);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
          this.errorMessage = error.error.message;
          this.showError = true;
        }
      );
    } else {
      setTimeout(() => {
        this.showSpinner = false;
        this.showError = true;
        this.errorMessage = 'Please search for an image';
      }, 1000);
    }
  }

  public loadImage(event, item , name) {
    localStorage.setItem('term_type_id' , '2');
    localStorage.setItem('image_id' , item);
    sessionStorage.setItem('breadCrumbImage' , sessionStorage.getItem('q'));
    this.router.navigate(['/productDetails' , { id: item , backdrop: name}]);
  }

  public loadPackage(event, item , name) {
    localStorage.setItem('term_type_id', 'showPackages');
    localStorage.setItem('package_id', item);
    sessionStorage.setItem('breadCrumbImage' , sessionStorage.getItem('q'));
    this.router.navigate(['/productDetails', { packageId: item , backdropPackage: name}]);
  }
}
