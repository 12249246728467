import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  myRecaptcha: boolean;
  email: any = '';
  showError: boolean;
  showSuccess: boolean;
  showSpinner: boolean;
  errorMessage: any = '';
  resetToken: any;
  password: any;
  repeatPassword: any;

  constructor(private updatePassword: DataService ,  private router: Router,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Reset Password - Backdrop Projections");

    const pathArray = window.location.pathname.split( '/' );
    const urlToken = pathArray[2];
    this.resetToken = urlToken ;

    this.updatePassword.passwordResetEmail(this.resetToken).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.email = data.success.email;
        }

      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
    console.log(typeof(this.email));
    if (this.email == null) {
      this.router.navigate(['/forgotPassword']);
    }
  }

  passwordUpdate({ value, valid }: { value: any, valid: boolean }) {
    this.showSpinner = true;
    if (!valid) {
      console.log('not valid');
    } else {
      this.updatePassword.resetPassword(value['resetToken'] , this.email , value['password']).subscribe(
        (data: any) => {
          console.log(data);
          if (data.success) {
            setTimeout(() => {
              this.showSpinner = false;
              this.showSuccess = true;
            }, 2000);
            // localStorage.removeItem('resetEmail');
             setTimeout(() => {
                this.router.navigate(['/login']);
            }, 7000);
          }

        },
        (error: any) => {
          console.log(error);
          if (error.status == 500) {
            this.router.navigate(['/error']);
          }
          this.errorMessage = error.error;
          console.log(this.errorMessage);
          setTimeout(() => {
            this.showSpinner = false;
          }, 1000);
          setTimeout(() => {
          this.showError = true;
        }, 1000);
          setTimeout(() => {
            this.showError = false;
            this.router.navigate(['/forgotPassword']);
          }, 5000);
        }
      );
    }
  }
}
