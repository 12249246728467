import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  myRecaptcha: boolean;
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  name: any;
  email: any;
  phone: any;
  message: any;
  user_id: any = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');
  userDetails: any;
  isLoggedIn = false;

  constructor(private contact: DataService, private router: Router,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Contact Us - Backdrop Projections");

    if (this.user_id != null) {
      this.isLoggedIn = true;
      this.contact.getUserDetails(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.userDetails = data.success.user;
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }
  }

  contactUs({ value, valid }: { value: any, valid: boolean }) {
    this.showSpinner = true;
    if (!valid) {
      console.log('not valid');
    } else {
      this.contact.contactForm(value['first_name'] , value['last_name'] ,  value['email'] , value['phone'], value['message']).subscribe(
        (data: any) => {
          console.log(data);
          if (data.success) {
            // alert('Welcome')
            setTimeout(() => {
              this.showSpinner = false;
              this.showSuccess = true;
            }, 2000);
            setTimeout(() => {
              this.router.navigate(['/contactThankYou']);
            }, 500);
          }

        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
          this.errorMessage = error.error.message;
          for (const key in this.errorMessage) {
            if (this.errorMessage.hasOwnProperty(key)) {
                console.log(this.errorMessage[key]);
            }
        }
          setTimeout(() => {
            this.showSpinner = false;
          }, 1000);
          setTimeout(() => {
          this.showError = true;
        }, 1000);
          setTimeout(() => {
            this.showError = false;
          }, 5000);
        }

      );
    }
  }

}
