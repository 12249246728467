import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {isDevMode} from '@angular/core';
import {local} from '../../../environments/local';
import {PreviousRouteService} from '../../services/previous-route.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {

  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  id: any = localStorage.getItem('image_id');
  images: any;
  packageNames: any;
  packageImages: any;
  packageIds: any;
  isLoggedIn: boolean;
  path: any;
  user_id: any = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');
  favoriteLinks: any;
  favoritePackageLinks: any;

  constructor(private favorites: DataService, private router: Router, private previousRouteService: PreviousRouteService, private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle("Favorites - Backdrop Projections");

    this.showSpinner = true;
    console.log(this.previousRouteService.getPreviousUrl());

    this.path = local.imageUrl + '/storage';


    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.favorites.getFavorites(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            setTimeout(() => {
              this.showSpinner = false;
              this.images = data.success.favorites;
              this.favoriteLinks = data.success.favoriteLinks;
              for (let i = 0; i < this.favoriteLinks.length; i++) {
                this.favoriteLinks [i].name = this.favoriteLinks [i]['name'].replace(/[' ']/g, '-');
              }
              this.packageNames = data.success.favoritePackageNames;
              this.packageImages = data.success.favoritePackageImages;
              this.favoritePackageLinks = data.success.favoritePackageLinks;
              for (let i = 0; i < this.favoritePackageLinks.length; i++) {
                this.favoritePackageLinks[i] = this.favoritePackageLinks[i].replace(/[' ']/g, '-');
              }
              this.packageIds = data.success.packageID;
            }, 2000);
          }
        },
        (error: any) => {
          this.router.navigate(['/error']);
          console.log(error);
        }
      );
    } else {
      setTimeout(() => {
        this.showSpinner = false;
        this.showError = true;
        this.errorMessage = 'You need to be logged in';
      }, 2000);
    }

  }

  public loadImage(event, item, name) {
    localStorage.setItem('term_type_id', '2');
    localStorage.setItem('image_id', item);
    sessionStorage.setItem('breadCrumbImage', '');
    this.router.navigate(['/productDetails', {id: item, backdrop: name}]);
  }

  public loadPackage(event, item, name) {
    localStorage.setItem('term_type_id', 'showPackages');
    localStorage.setItem('package_id', item);
    sessionStorage.setItem('breadCrumbImage', '');
    this.router.navigate(['/productDetails', {packageId: item, backdropPackage: name}]);
  }
}
