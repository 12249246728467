import { Component, OnInit , Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isDevMode } from '@angular/core';
import { local } from '../../../environments/local';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  myRecaptcha: boolean;
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  title: any;
  termId: any;
  term: any;
  termName: any;
  backdropsBy: any ;
  recentImages: any;
  categories: any;
  images: any;
  path: any;
  showPackages: boolean;
  featuredPackageImage: any;
  animation: boolean;
  static: boolean;
  categoryLink: any;
  recentName: any;
  p: number;
  pageSize = 102;
  hide = false;
  staticImagesPage: boolean;
  animationsPage: boolean;
  selectedCategoryIndex = Number(sessionStorage.getItem('categoryItemIndex')) ? Number(sessionStorage.getItem('categoryItemIndex')) : null;
  selectedThemeIndex = Number(sessionStorage.getItem('themeItemIndex')) ? Number(sessionStorage.getItem('themeItemIndex')) : null;

  constructor(private browse: DataService, private router: Router, private route: ActivatedRoute , private titleService:Title) { }

  ngOnInit() {

    if (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1) {
      this.hide = true;
    } else {
      this.hide = true;
    }

    this.path = local.baseUrl + '/storage';
    this.route.params.subscribe(params =>
      this.termId = params['term_type_id']);

      this.route.params.subscribe(params =>
        this.backdropsBy = params['backdropsBy']);

        localStorage.setItem('term' , this.termId);
        localStorage.setItem('termName' , this.backdropsBy);

        if (this.termId !== null) {
        localStorage.setItem('term_type_id' , this.termId);
        }
    if (this.backdropsBy === 'Static-Images') {
      this.staticImagesPage = true;
    }

    if (this.backdropsBy === 'Animations') {
      this.animationsPage = true;
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.showSpinner = true;

    switch (this.termId) {
      case '1': {
        this.title = 'Category';
        break;
      }
      case '2': {
        this.title = 'Show Theme';
        break;
      }
      case 'showPackages': {
        this.title = 'Curated Show Packages';
        break;
      }
      case 'animations': {
        this.title = 'Animations';
        break;
      }
      case 'static': {
        this.title = 'Static Images';
        break;
      }
      default: {
        this.title = '';
        break;
      }
    }

    this.titleService.setTitle('Browse by ' + this.title+" - Backdrop Projections");


    this.browse.browseBy(this.termId, this.title).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.showSpinner = false;
          if (this.termId !== 'showPackages') {
            this.animation = false;
            this.static = false;
            this.categories = data.success.categories;
            const filtered = this.categories.filter(function (el) {
              return el['image_term'] != null && el['image_term']['image'] != null;
            });
            this.categories = filtered;
            this.categoryLink = data.success.links;
            const filteredLinks = this.categoryLink.filter(function (el) {
              return el['image_term'] != null && el['image_term']['image'] != null;
            });
            this.categoryLink = filteredLinks;
            for (let i = 0 ; i <  this.categoryLink.length; i++) {
              this.categoryLink[i].name  = this.categoryLink[i].name.replace(/[' ']/g, '-');
              // this.categories[i].name  = this.categories[i].name.replace(/-/g, ' ');
            }
          }

          if (this.termId === 'animations') {
            this.animation = true;
            this.categories = data.success.categories;
            this.categoryLink = data.success.links;
            for (let i = 0 ; i < this.categoryLink.length; i++) {
              this.categoryLink[i].name =    this.categoryLink[i]['name'].replace(/['_']/g, '-');
              this.categoryLink[i].name =    this.categoryLink[i]['name'].replace(/[' ']/g, '-');
            }
          }

          if (this.termId === 'static') {
            this.static = true;
            this.categories = data.success.categories;
            this.categoryLink = data.success.links;
            for (let i = 0 ; i < this.categoryLink.length; i++) {
              this.categoryLink[i].name =    this.categoryLink[i]['name'].replace(/[' ']/g, '-');
            }
          }

          if (this.termId === 'showPackages') {
            this.animation = false;
            this.static = false;
            this.showPackages = true;
            this.categories = data.success.categories;
            this.categoryLink = data.success.links;
            this.featuredPackageImage = data.success.featured_image;
            for (let i = 0 ; i < this.categoryLink.length; i++) {
              this.categoryLink[i].name =   this.categoryLink[i]['name'].replace(/[' ']/g, '-');
            }
            localStorage.setItem('termName' , data.success.browseBy.replace(/[' ']/g, '-'));
          }

          this.recentImages = JSON.parse(sessionStorage.getItem('recentImages'));
          this.recentName = JSON.parse(sessionStorage.getItem('recentImages'));
          console.log(this.recentImages);

          for (let i = 0 ; i < this.recentImages.length; i++) {
            this.recentImages[i][0].name =   this.recentImages[i][0]['name'].replace(/[' ']/g, '-');
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
        this.errorMessage = error.error.message;
        this.showError = true;
      }
    );
  }
  showMore($event) {
    this.hide = false;
  }

  setCategoryIndex(index: number) {
    sessionStorage.setItem('categoryItemIndex' , String(index));
    sessionStorage.removeItem('breadCrumbImage');
    }

    setThemeIndex(index: number) {
      sessionStorage.setItem('themeItemIndex' , String(index));
      sessionStorage.removeItem('breadCrumbImage');
      }

      clearBreadcrumbSession()
      {
        sessionStorage.removeItem('breadCrumbImage');
      }

}
