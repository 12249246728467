import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  myRecaptcha: boolean;
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  resetToken: any;
  password: any;
  repeatPassword: any;
  email: any;

  constructor(private forgotPassword: DataService, private router: Router,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Forgot Password - Backdrop Projections");

  }

  passwordReset({ value, valid }: { value: any, valid: boolean }) {
    this.showSpinner = true;
    if (!valid) {
      console.log('not valid');
    } else {
      this.forgotPassword.forgotPassword(value['email']).subscribe(
        (data: any) => {
          console.log(data);
          if (data.success) {
            localStorage.setItem('resetEmail', value['email']);
            this.showSuccess = true;
            this.showError = false;
            this.showSpinner = false;
            // setTimeout(() => {
            //   this.router.navigate(['/resetPassword']);
            // }, 7000);
          }
        },
        (error: any) => {
         console.log(error);
         if (error.status == 500) {
          this.router.navigate(['/error']);
         }
          this.showSuccess = false;
          setTimeout(() => {
            this.showSpinner = false;
            this.showError = true;
          }, 1000);
          setTimeout(() => {
            this.showError = false;
          }, 5000);
        }

      );
    }
  }

}
