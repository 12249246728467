import { Component, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-secondary-nav",
  templateUrl: "./secondary-nav.component.html",
  styleUrls: ["./secondary-nav.component.css"],
})
export class SecondaryNavComponent implements OnInit {
  isMatMenuOpen = false;
  enteredButton = false;
  isMatMenu2Open = false;
  prevButtonTrigger: any = "";
  constructor(private ren: Renderer2) {}

  ngOnInit(): void {}

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }
  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-focused"
        );
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-program-focused"
        );
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-focused"
        );
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-program-focused"
        );
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }
}
