import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../../services/data.service";
import { isDevMode } from "@angular/core";
import { local } from "../../../environments/local";
import { Title } from "@angular/platform-browser";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { InstallationGuideComponent } from "../installation-guide/installation-guide.component";

@Component({
  selector: "app-order-complete",
  templateUrl: "./order-complete.component.html",
  styleUrls: ["./order-complete.component.css"],
})
export class OrderCompleteComponent implements OnInit {
mac_intel: any;
mac_silicon:any;
  windows: any;
  path: any;

  constructor(
    private router: Router,
    private order: DataService,
    private titleService: Title,
    public dialog: MatDialog
  ) {}

  openInstallationGuideDialog(): void {
    const dialogRef = this.dialog.open(InstallationGuideComponent, {
      width: "1024px",
      height: "600px",
    });

    document.getElementById("close").onclick = function () {
      dialogRef.close();
    };

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Order Complete - Backdrop Projections");

    this.path = local.imageUrl + "/storage";

    this.order.getApp().subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.windows = data.success.windows;
          this.mac_intel = data.success.mac_intel;
          this.mac_silicon = data.success.mac_silicon;

        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(["/error"]);
      }
    );
  }
}
