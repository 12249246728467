import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hand-painted-backdrops',
  templateUrl: './hand-painted-backdrops.component.html',
  styleUrls: ['./hand-painted-backdrops.component.css']
})
export class HandPaintedBackdropsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
load($event) {
  window.open('http://backdropsbeautiful.com/');
}

}
