import { Component, OnInit, Input, Renderer2, Optional } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DataService } from "../../services/data.service";
import { HandPaintedBackdropsComponent } from "../hand-painted-backdrops/hand-painted-backdrops.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean;
  userName: string;
  clicked: boolean = false;
  toggle: boolean = false;
  toggleSub: boolean = false;
  toggleSub2: boolean = false;
  images: any;
  q: any;
  user_id: any = localStorage.getItem("currentUserId")
    ? localStorage.getItem("currentUserId")
    : sessionStorage.getItem("currentUserId");
  favoriteImages: any;
  favoritePackages: any;
  countFavorites: any;
  countCart: any;
  @Input() likes;
  @Input() cartItemCount;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger: any = "";
  categories: any;
  themes: any;
  categoryLink: any;
  themeLink: any;
  screenSize: any;
  selectedCategoryIndex = Number(sessionStorage.getItem("categoryItemIndex"))
    ? Number(sessionStorage.getItem("categoryItemIndex"))
    : null;
  selectedThemeIndex = Number(sessionStorage.getItem("themeItemIndex"))
    ? Number(sessionStorage.getItem("themeItemIndex"))
    : null;
  page: any;
  submenu_open = 0;

  constructor(
    private service: DataService,
    private router: Router,
    @Optional() public dialogRef: MatDialogRef<HandPaintedBackdropsComponent>,
    public dialog: MatDialog,
    private ren: Renderer2,
    private route: ActivatedRoute
  ) {}
  openDialog(): void {
    this.dialog.open(HandPaintedBackdropsComponent, {
      height: "600px",
      width: "1024px",
    });
  }
  closeDialog(): void {
    this.dialogRef.close("Modal closed");
  }
  ngOnInit() {
    this.route.params.subscribe(
      (params) => (this.page = params["backdropsBy"])
    );

    if (this.page !== "Show-Theme" || this.page !== "Category") {
      sessionStorage.removeItem("categoryItemIndex");
      sessionStorage.removeItem("themeItemIndex");
    }

    this.service.getMaintenanceMode().subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          if (data.success.maintenanceModeStatus[0]["status"] === "on") {
            this.router.navigate(["/maintenance"]);
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(["/error"]);
      }
    );

    if (localStorage.getItem("currentUserId") !== null) {
      this.service
        .checkUserStorageData(localStorage.getItem("currentUserId"))
        .subscribe(
          (data: any) => {
            if (data.success) {
              console.log(data.success);
              if (data.success.validUser.length === 0) {
                localStorage.removeItem("currentUserId");
                localStorage.removeItem("currentUserName");
                localStorage.removeItem("remember_me");
                this.router.navigate(["/login"]);
              }
            }
          },
          (error: any) => {
            console.log(error);
            this.router.navigate(["/error"]);
          }
        );
    }

    this.screenSize = window.innerWidth;

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    if (
      sessionStorage.getItem("currentUserName") ||
      localStorage.getItem("currentUserName")
    ) {
      this.isLoggedIn = true;
      this.userName = sessionStorage.getItem("currentUserName")
        ? sessionStorage.getItem("currentUserName")
        : localStorage.getItem("currentUserName");
    }

    if (
      localStorage.getItem("currentUserId") ||
      sessionStorage.getItem("currentUserId")
    ) {
      this.service.getFavorites(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.favoriteImages = data.success.favorites;
            this.favoritePackages = data.success.favoritePackageNames;
            this.countFavorites =
              this.favoriteImages.length + this.favoritePackages.length;
            console.log(this.countFavorites);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(["/error"]);
        }
      );
    } else {
      this.countFavorites = 0;
    }

    if (
      localStorage.getItem("currentUserId") ||
      sessionStorage.getItem("currentUserId")
    ) {
      this.service.countCart(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.countCart = data.success.cartItems;
            console.log(this.countCart);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(["/error"]);
        }
      );
    } else {
      this.countCart = 0;
    }

    window.onscroll = function () {
      minifiedMenu();
    };

    function minifiedMenu() {
      let navMenu = document.body.querySelectorAll(".navigation");
      // let navMenuMobile = document.querySelectorAll('.cdk-overlay-container');
      for (let i = 0; i < navMenu.length; i++) {
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          navMenu[i].classList.add("minified");
        } else {
          navMenu[i].classList.remove("minified");
        }
      }
      // for (let i = 0; i < navMenuMobile.length; i++) {
      //   if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      //     navMenuMobile[i].classList.add('minified');
      //   } else {
      //     navMenuMobile[i].classList.remove('minified');
      //   }
      //  }
    }
  }

  Logout() {
    if (localStorage.getItem("remember_me")) {
      localStorage.removeItem("currentUserId");
      localStorage.removeItem("currentUserName");
      localStorage.removeItem("token");
      localStorage.removeItem("remember_me");
      location.reload();
    } else {
      sessionStorage.removeItem("currentUserId");
      sessionStorage.removeItem("currentUserName");
      sessionStorage.removeItem("token");
      location.reload();
    }
  }

  public search() {
    setTimeout(() => document.getElementById("q").focus());
    this.clicked = !this.clicked;
  }

  searchImage({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      console.log("not valid");
    } else {
      this.q = sessionStorage.setItem("q", value["q"]);
      setTimeout(() => {
        location.reload();
      }, 500);
      this.router.navigate(["/search"], { queryParams: { q: value["q"] } });
    }
  }

  // Desktop menu hover functionality
  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-focused"
        );
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-program-focused"
        );
      } else {
        this.isMatMenuOpen = false;
      }
    }, 500);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
    // document.getElementById('hide-hover-theme').removeAttribute('disabled');
    document
      .getElementById("menu-overlay-item")
      .setAttribute("style", "display:none");
    console.log("Changed disabled state");
  }

  menu2Leave(trigger1, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-focused"
        );
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-program-focused"
        );
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100);
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  keepMenuOpen() {
    // document.getElementById('hide-hover-theme').removeAttribute('disabled');
    setTimeout(() => {
      document
        .getElementById("menu-overlay-item")
        .setAttribute("style", "display:none");
      console.log("Changed disabled state");
    }, 250);
    this.isMatMenu2Open = true;
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-focused"
        );
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-program-focused"
        );
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-focused"
        );
        this.ren.removeClass(
          button["_elementRef"].nativeElement,
          "cdk-program-focused"
        );
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  categoryRequest() {
    this.submenu_open = 1;
    this.isMatMenu2Open = true;
    var myElement = document.querySelector(".categorySub");
    myElement.setAttribute("style", "display: block");
    var myElement1 = document.querySelector(".hide-hover-theme");
    myElement1.setAttribute(
      "style",
      "position: absolute;top: 48px;left: 0;width: 100%;height: 48px;z-index: 3;visibility: visible;"
    );
    // document.getElementById('hide-hover-theme').setAttribute('disabled','disabled');
    console.log("category");
    this.service.browseBy(1, "Category").subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.categories = data.success.categories;
          this.categoryLink = data.success.links;
          localStorage.setItem("term_type_id", "1");
          for (let i = 0; i < this.categoryLink.length; i++) {
            this.categoryLink[i].name = this.categoryLink[i].name.replace(
              /[' ']/g,
              "-"
            );
            // this.categories[i].name  = this.categories[i].name.replace(/-/g, ' ');
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(["/error"]);
      }
    );
  }

  clickTheme() {
    document.getElementById("categoryMenu").click();
    document.getElementById("hide-hover-theme").click();

    console.log("click");
  }

  themeRequest() {
    this.isMatMenu2Open = true;
    var myElement = document.querySelector(".themeSub");
    myElement.setAttribute("style", "display: block");
    console.log("theme");
    this.service.browseBy(2, "Show Theme").subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.themes = data.success.categories;
          this.themeLink = data.success.links;
          localStorage.setItem("term_type_id", "2");
          for (let i = 0; i < this.themeLink.length; i++) {
            this.themeLink[i].name = this.themeLink[i].name.replace(
              /[' ']/g,
              "-"
            );
            // this.categories[i].name  = this.categories[i].name.replace(/-/g, ' ');
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(["/error"]);
      }
    );
  }

  closeSearch() {
    this.clicked = false;
  }

  closeToggleMenu() {
    this.toggle = false;
    this.toggleSub = false;
    this.toggleSub2 = false;
  }

  toggleMenu() {
    if (this.toggle === true) {
      this.toggleSub = false;
      this.toggleSub2 = false;
    }
    this.toggle = !this.toggle;
  }
  toggleSubMenu() {
    this.toggleSub = !this.toggleSub;
  }
  toggleSubMenu2() {
    this.toggleSub2 = !this.toggleSub2;
  }

  setCategoryIndex(index: number) {
    sessionStorage.setItem("categoryItemIndex", String(index));
  }

  setThemeIndex(index: number) {
    sessionStorage.setItem("themeItemIndex", String(index));
  }
}
