import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Router } from "@angular/router";
import { isDevMode } from "@angular/core";
// import { PaymentOptionsComponent } from "../payment-options/payment-options.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { local } from "../../../environments/local";
import { Title } from "@angular/platform-browser";
import { Country } from "src/app/country";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  myRecaptcha: any;
  cartItems: any;
  cartPackageItems: any;
  isLoggedIn: boolean;
  user_id: any = localStorage.getItem("currentUserId")
    ? localStorage.getItem("currentUserId")
    : sessionStorage.getItem("currentUserId");
  path: any;
  images: any;
  packageImages: any;
  total: any;
  subTotal: any;
  tax: any;
  cartEmpty = false;
  userDetails: any;
  showSpinner: boolean;
  hasPromo: any;
  discount: any;
  Accept: any;
  error: any;
  success: any;
  selectedCountry: any;
  hide: any;
  newsletter = true;
  apiLoginID: string;
  clientKey: string;
  zeroTotal = false;

  constructor(
    private checkoutCart: DataService,
    private router: Router,
    public dialog: MatDialog,
    private titleService: Title // // public countries: Country[]
  ) {}

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(null, {
  //     width: "1250px",
  //     height: "600px",
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log("The dialog was closed");
  //   });
  // }
  // onCountryChange(eventTar: any) {
  //   this.selectedCountry = eventTar.target.value;
  //   return console.log(this.selectedCountry, "selected country");
  // }
  ngOnInit() {
    // this.countries = [
    //   { value: "Afghanistan", label: "Afghanistan" },
    //   { value: "Åland Islands", label: "Åland Islands" },
    //   { value: "Albania", label: "Albania" },
    //   { value: "Algeria", label: "Algeria" },
    //   { value: "American Samoa", label: "American Samoa" },
    //   { value: "Andorra", label: "Andorra" },
    //   { value: "Angola", label: "Angola" },
    //   { value: "Anguilla", label: "Anguilla" },
    //   { value: "Antarctica", label: "Antarctica" },
    //   { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    //   { value: "Argentina", label: "Argentina" },
    //   { value: "Armenia", label: "Armenia" },
    //   { value: "Aruba", label: "Aruba" },
    //   { value: "Australia", label: "Australia" },
    //   { value: "Austria", label: "Austria" },
    //   { value: "Azerbaijan", label: "Azerbaijan" },
    //   { value: "Bahamas", label: "Bahamas" },
    //   { value: "Bahrain", label: "Bahrain" },
    //   { value: "Bangladesh", label: "Bangladesh" },
    //   { value: "Barbados", label: "Barbados" },
    //   { value: "Belarus", label: "Belarus" },
    //   { value: "Belgium", label: "Belgium" },
    //   { value: "Belize", label: "Belize" },
    //   { value: "Benin", label: "Benin" },
    //   { value: "Bermuda", label: "Bermuda" },
    //   { value: "Bhutan", label: "Bhutan" },
    //   { value: "Bolivia", label: "Bolivia" },
    //   { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    //   { value: "Botswana", label: "Botswana" },
    //   { value: "Bouvet Island", label: "Bouvet Island" },
    //   { value: "Brazil", label: "Brazil" },
    //   {
    //     value: "British Indian Ocean Territory",
    //     label: "British Indian Ocean Territory",
    //   },
    //   { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    //   { value: "Bulgaria", label: "Bulgaria" },
    //   { value: "Burkina Faso", label: "Burkina Faso" },
    //   { value: "Burundi", label: "Burundi" },
    //   { value: "Cambodia", label: "Cambodia" },
    //   { value: "Cameroon", label: "Cameroon" },
    //   { value: "Canada", label: "Canada" },
    //   { value: "Cape Verde", label: "Cape Verde" },
    //   { value: "Cayman Islands", label: "Cayman Islands" },
    //   { value: "Central African Republic", label: "Central African Republic" },
    //   { value: "Chad", label: "Chad" },
    //   { value: "Chile", label: "Chile" },
    //   { value: "China", label: "China" },
    //   { value: "Christmas Island", label: "Christmas Island" },
    //   { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    //   { value: "Colombia", label: "Colombia" },
    //   { value: "Comoros", label: "Comoros" },
    //   { value: "Congo", label: "Congo" },
    //   {
    //     value: "Congo, The Democratic Republic of The",
    //     label: "Congo, The Democratic Republic of The",
    //   },
    //   { value: "Cook Islands", label: "Cook Islands" },
    //   { value: "Costa Rica", label: "Costa Rica" },
    //   { value: "Cote D'ivoire", label: "Cote D'ivoire" },
    //   { value: "Croatia", label: "Croatia" },
    //   { value: "Cuba", label: "Cuba" },
    //   { value: "Curaçao", label: "Curaçao" },
    //   { value: "Cyprus", label: "Cyprus" },
    //   { value: "Czech Republic", label: "Czech Republic" },
    //   { value: "Denmark", label: "Denmark" },
    //   { value: "Djibouti", label: "Djibouti" },
    //   { value: "Dominica", label: "Dominica" },
    //   { value: "Dominican Republic", label: "Dominican Republic" },
    //   { value: "Ecuador", label: "Ecuador" },
    //   { value: "Egypt", label: "Egypt" },
    //   { value: "El Salvador", label: "El Salvador" },
    //   { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    //   { value: "Eritrea", label: "Eritrea" },
    //   { value: "Estonia", label: "Estonia" },
    //   { value: "Ethiopia", label: "Ethiopia" },
    //   {
    //     value: "Falkland Islands (Malvinas)",
    //     label: "Falkland Islands (Malvinas)",
    //   },
    //   { value: "Faroe Islands", label: "Faroe Islands" },
    //   { value: "Fiji", label: "Fiji" },
    //   { value: "Finland", label: "Finland" },
    //   { value: "France", label: "France" },
    //   { value: "French Guiana", label: "French Guiana" },
    //   { value: "French Polynesia", label: "French Polynesia" },
    //   {
    //     value: "French Southern Territories",
    //     label: "French Southern Territories",
    //   },
    //   { value: "Gabon", label: "Gabon" },
    //   { value: "Gambia", label: "Gambia" },
    //   { value: "Georgia", label: "Georgia" },
    //   { value: "Germany", label: "Germany" },
    //   { value: "Ghana", label: "Ghana" },
    //   { value: "Gibraltar", label: "Gibraltar" },
    //   { value: "Greece", label: "Greece" },
    //   { value: "Greenland", label: "Greenland" },
    //   { value: "Grenada", label: "Grenada" },
    //   { value: "Guadeloupe", label: "Guadeloupe" },
    //   { value: "Guam", label: "Guam" },
    //   { value: "Guatemala", label: "Guatemala" },
    //   { value: "Guernsey", label: "Guernsey" },
    //   { value: "Guinea", label: "Guinea" },
    //   { value: "Guinea-bissau", label: "Guinea-bissau" },
    //   { value: "Guyana", label: "Guyana" },
    //   { value: "Haiti", label: "Haiti" },
    //   {
    //     value: "Heard Island and Mcdonald Islands",
    //     label: "Heard Island and Mcdonald Islands",
    //   },
    //   {
    //     value: "Holy See (Vatican City State)",
    //     label: "Holy See (Vatican City State)",
    //   },
    //   { value: "Honduras", label: "Honduras" },
    //   { value: "Hong Kong", label: "Hong Kong" },
    //   { value: "Hungary", label: "Hungary" },
    //   { value: "Iceland", label: "Iceland" },
    //   { value: "India", label: "India" },
    //   { value: "Indonesia", label: "Indonesia" },
    //   {
    //     value: "Iran, Islamic Republic of",
    //     label: "Iran, Islamic Republic of",
    //   },
    //   { value: "Iraq", label: "Iraq" },
    //   { value: "Ireland", label: "Ireland" },
    //   { value: "Isle of Man", label: "Isle of Man" },
    //   { value: "Israel", label: "Israel" },
    //   { value: "Italy", label: "Italy" },
    //   { value: "Jamaica", label: "Jamaica" },
    //   { value: "Japan", label: "Japan" },
    //   { value: "Jersey", label: "Jersey" },
    //   { value: "Jordan", label: "Jordan" },
    //   { value: "Kazakhstan", label: "Kazakhstan" },
    //   { value: "Kenya", label: "Kenya" },
    //   { value: "Kiribati", label: "Kiribati" },
    //   {
    //     value: "Korea, Democratic People's Republic of",
    //     label: "Korea, Democratic People's Republic of",
    //   },
    //   { value: "Korea, Republic of", label: "Korea, Republic of" },
    //   { value: "Kuwait", label: "Kuwait" },
    //   { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    //   {
    //     value: "Lao People's Democratic Republic",
    //     label: "Lao People's Democratic Republic",
    //   },
    //   { value: "Latvia", label: "Latvia" },
    //   { value: "Lebanon", label: "Lebanon" },
    //   { value: "Lesotho", label: "Lesotho" },
    //   { value: "Liberia", label: "Liberia" },
    //   { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    //   { value: "Liechtenstein", label: "Liechtenstein" },
    //   { value: "Lithuania", label: "Lithuania" },
    //   { value: "Luxembourg", label: "Luxembourg" },
    //   { value: "Macao", label: "Macao" },
    //   {
    //     value: "Macedonia, The Former Yugoslav Republic of",
    //     label: "Macedonia, The Former Yugoslav Republic of",
    //   },
    //   { value: "Madagascar", label: "Madagascar" },
    //   { value: "Malawi", label: "Malawi" },
    //   { value: "Malaysia", label: "Malaysia" },
    //   { value: "Maldives", label: "Maldives" },
    //   { value: "Mali", label: "Mali" },
    //   { value: "Malta", label: "Malta" },
    //   { value: "Marshall Islands", label: "Marshall Islands" },
    //   { value: "Martinique", label: "Martinique" },
    //   { value: "Mauritania", label: "Mauritania" },
    //   { value: "Mauritius", label: "Mauritius" },
    //   { value: "Mayotte", label: "Mayotte" },
    //   { value: "Mexico", label: "Mexico" },
    //   {
    //     value: "Micronesia, Federated States of",
    //     label: "Micronesia, Federated States of",
    //   },
    //   { value: "Moldova, Republic of", label: "Moldova, Republic of" },
    //   { value: "Monaco", label: "Monaco" },
    //   { value: "Mongolia", label: "Mongolia" },
    //   { value: "Montenegro", label: "Montenegro" },
    //   { value: "Montserrat", label: "Montserrat" },
    //   { value: "Morocco", label: "Morocco" },
    //   { value: "Mozambique", label: "Mozambique" },
    //   { value: "Myanmar", label: "Myanmar" },
    //   { value: "Namibia", label: "Namibia" },
    //   { value: "Nauru", label: "Nauru" },
    //   { value: "Nepal", label: "Nepal" },
    //   { value: "Netherlands", label: "Netherlands" },
    //   { value: "New Caledonia", label: "New Caledonia" },
    //   { value: "New Zealand", label: "New Zealand" },
    //   { value: "Nicaragua", label: "Nicaragua" },
    //   { value: "Niger", label: "Niger" },
    //   { value: "Nigeria", label: "Nigeria" },
    //   { value: "Niue", label: "Niue" },
    //   { value: "Norfolk Island", label: "Norfolk Island" },
    //   { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    //   { value: "Norway", label: "Norway" },
    //   { value: "Oman", label: "Oman" },
    //   { value: "Pakistan", label: "Pakistan" },
    //   { value: "Palau", label: "Palau" },
    //   {
    //     value: "Palestinian Territory, Occupied",
    //     label: "Palestinian Territory, Occupied",
    //   },
    //   { value: "Panama", label: "Panama" },
    //   { value: "Papua New Guinea", label: "Papua New Guinea" },
    //   { value: "Paraguay", label: "Paraguay" },
    //   { value: "Peru", label: "Peru" },
    //   { value: "Philippines", label: "Philippines" },
    //   { value: "Pitcairn", label: "Pitcairn" },
    //   { value: "Poland", label: "Poland" },
    //   { value: "Portugal", label: "Portugal" },
    //   { value: "Puerto Rico", label: "Puerto Rico" },
    //   { value: "Qatar", label: "Qatar" },
    //   { value: "Reunion", label: "Reunion" },
    //   { value: "Romania", label: "Romania" },
    //   { value: "Russia", label: "Russia" },
    //   { value: "Rwanda", label: "Rwanda" },
    //   { value: "Saint Helena", label: "Saint Helena" },
    //   { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    //   { value: "Saint Lucia", label: "Saint Lucia" },
    //   {
    //     value: "Saint Pierre and Miquelon",
    //     label: "Saint Pierre and Miquelon",
    //   },
    //   {
    //     value: "Saint Vincent and The Grenadines",
    //     label: "Saint Vincent and The Grenadines",
    //   },
    //   { value: "Samoa", label: "Samoa" },
    //   { value: "San Marino", label: "San Marino" },
    //   { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    //   { value: "Saudi Arabia", label: "Saudi Arabia" },
    //   { value: "Senegal", label: "Senegal" },
    //   { value: "Serbia", label: "Serbia" },
    //   { value: "Seychelles", label: "Seychelles" },
    //   { value: "Sierra Leone", label: "Sierra Leone" },
    //   { value: "Singapore", label: "Singapore" },
    //   { value: "Slovakia", label: "Slovakia" },
    //   { value: "Slovenia", label: "Slovenia" },
    //   { value: "Solomon Islands", label: "Solomon Islands" },
    //   { value: "Somalia", label: "Somalia" },
    //   { value: "South Africa", label: "South Africa" },
    //   {
    //     value: "South Georgia and The South Sandwich Islands",
    //     label: "South Georgia and The South Sandwich Islands",
    //   },
    //   { value: "Spain", label: "Spain" },
    //   { value: "Sri Lanka", label: "Sri Lanka" },
    //   { value: "Sudan", label: "Sudan" },
    //   { value: "Suriname", label: "Suriname" },
    //   { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    //   { value: "Eswatini", label: "Eswatini" },
    //   { value: "Sweden", label: "Sweden" },
    //   { value: "Switzerland", label: "Switzerland" },
    //   { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    //   { value: "Taiwan (ROC)", label: "Taiwan (ROC)" },
    //   { value: "Tajikistan", label: "Tajikistan" },
    //   {
    //     value: "Tanzania, United Republic of",
    //     label: "Tanzania, United Republic of",
    //   },
    //   { value: "Thailand", label: "Thailand" },
    //   { value: "Timor-leste", label: "Timor-leste" },
    //   { value: "Togo", label: "Togo" },
    //   { value: "Tokelau", label: "Tokelau" },
    //   { value: "Tonga", label: "Tonga" },
    //   { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    //   { value: "Tunisia", label: "Tunisia" },
    //   { value: "Turkey", label: "Turkey" },
    //   { value: "Turkmenistan", label: "Turkmenistan" },
    //   { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    //   { value: "Tuvalu", label: "Tuvalu" },
    //   { value: "Uganda", label: "Uganda" },
    //   { value: "Ukraine", label: "Ukraine" },
    //   { value: "United Arab Emirates", label: "United Arab Emirates" },
    //   { value: "United Kingdom", label: "United Kingdom" },
    //   { value: "United States", label: "United States" },
    //   {
    //     value: "United States Minor Outlying Islands",
    //     label: "United States Minor Outlying Islands",
    //   },
    //   { value: "Uruguay", label: "Uruguay" },
    //   { value: "Uzbekistan", label: "Uzbekistan" },
    //   { value: "Vanuatu", label: "Vanuatu" },
    //   { value: "Venezuela", label: "Venezuela" },
    //   { value: "Vietnam", label: "Vietnam" },
    //   { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    //   { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    //   { value: "Wallis and Futuna", label: "Wallis and Futuna" },
    //   { value: "Western Sahara", label: "Western Sahara" },
    //   { value: "Yemen", label: "Yemen" },
    //   { value: "Zambia", label: "Zambia" },
    //   { value: "Zimbabwe", label: "Zimbabwe" },
    // ];

    this.apiLoginID = local.apiLoginID;
    this.clientKey = local.clientKey;

    this.titleService.setTitle("Checkout - Backdrop Projections");
    localStorage.setItem("baseUrl", local.baseUrl);

    this.path = local.imageUrl + "/storage";

    if (sessionStorage.getItem("promo_code")) {
      this.hasPromo = true;
    }

    if (
      localStorage.getItem("currentUserId") ||
      sessionStorage.getItem("currentUserId")
    ) {
      this.isLoggedIn = true;
    }

    this.checkoutCart.getCartItems(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.cartItems = data.success.cartItems;
          this.cartPackageItems = data.success.cartPackageItems;

          this.images = data.success.images;
          this.packageImages = data.success.packageImages;

          this.total = data.success.total;

          if (this.hasPromo) {
            this.total =
              this.total - Number(sessionStorage.getItem("discount"));
            this.discount = Number(sessionStorage.getItem("discount"));
            if (this.total < 0) {
              this.total = 0;
              this.zeroTotal = true;
            }
          }

          this.subTotal = data.success.subTotal;
          this.tax = data.success.tax;
          console.log(this.total);
          console.log(this.subTotal);
          console.log(this.tax);
        }
      },
      (error: any) => {
        console.log(error);
        if (this.router.url !== "/paymentError") {
          this.router.navigate(["/error"]);
        }
      }
    );

    this.checkoutCart.getUserDetails(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.userDetails = data.success.user;
          if (
            data.success.user[0].country === null ||
            data.success.user[0].country === ""
          ) {
            this.selectedCountry = "USA";
          } else {
            this.selectedCountry = data.success.user[0].country;
          }
        }
      },
      (error: any) => {
        console.log(error);
        if (this.router.url !== "/paymentError") {
          this.router.navigate(["/error"]);
        }
      }
    );
  }

  ngAfterViewInit() {
    if (!this.zeroTotal) {
      setTimeout(function () {
        console.log("loading");
        const script = document.createElement("script");
        if (local.production === true) {
          script.src = "https://js.authorize.net/v3/AcceptUI.js";
        } else {
          script.src = "https://jstest.authorize.net/v3/AcceptUI.js";
        }

        document.head.appendChild(script);
      }, 5000);
    }
  }
  checkout({ value, valid }: { value: any; valid: boolean }) {
    // alert(value['newsletter']);
    if (!valid) {
      console.log("not valid");
    } else {
      const addressTwo = value["address_field2"]
        ? value["address_field2"]
        : " ";
      this.checkoutCart
        .updateUserDetails(
          this.user_id,
          value["first_name"],
          value["last_name"],
          value["country"],
          value["city"],
          value["state"],
          value["address_field1"] + " " + addressTwo,
          value["zip_code"],
          value["company_name"],
          value["phone"],
          value["newsletter"]
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            if (data.success) {
              console.log(data);
              this.checkoutCart.getUserDetails(this.user_id).subscribe(
                (api: any) => {
                  if (api.success) {
                    console.log(api.success);
                    this.userDetails = api.success.user;
                  }
                },
                (error: any) => {
                  console.log(error);
                  if (this.router.url !== "/paymentError") {
                    this.router.navigate(["/error"]);
                  }
                }
              );
            }
          },
          (error: any) => {
            console.log(error);
            if (this.router.url !== "/paymentError") {
              this.router.navigate(["/error"]);
            }
          }
        );
    }
  }

  processOrder() {
    this.hide = true;
    this.checkoutCart
      .processOrder(
        this.user_id,
        sessionStorage.getItem("discount"),
        sessionStorage.getItem("promo_code_id"),
        this.tax
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data.success) {
            console.log(data);
            setTimeout(() => {
              this.router.navigate(["/orderComplete"]);
            }, 1500);
          }
        },
        (error: any) => {
          console.log(error);
          if (this.router.url !== "/paymentError") {
            this.router.navigate(["/error"]);
          }
        }
      );
  }

  reload_page() {
    location.reload();
  }

  processPayment() {
    document.getElementById("save").click();
    this.showSpinner = true;
    // window.focus(); //force focus on the currenct window;
    // window.addEventListener('blur', function(e){
    //     if(document.activeElement == document.getElementById('AcceptUIContainer').firstChild)
    //     {
    //         alert('Focus Left Current Window and Moved to Iframe / Possible click!');
    //     }
    // });
    const check = setInterval(() => {
      let error_show = false;
      this.checkoutCart.getPaymentStatus(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            const test = document.getElementById("AcceptUIContainer");
            if (
              typeof window.orientation !== "undefined" ||
              navigator.userAgent.indexOf("IEMobile") !== -1
            ) {
              // alert('mobile');
              document.getElementById("AcceptUIContainer").style.left = "20%";
              document.getElementById("AcceptUIContainer").style.right = "20%";
              document.getElementById("AcceptUIContainer").style.top = "30%";
            } else {
              // alert('not mobile');
              document.getElementById("AcceptUIContainer").style.top = "64%";
            }
            const background = document.getElementById("AcceptUIBackground");
            if (test.className !== "show") {
              this.hide = true;
            }

            if (sessionStorage.getItem("data") === null) {
              // setTimeout(() => {
              if (
                (test.className !== "show" && data.success.status === null) 
              ) {
                if (!error_show) {
                  // location.reload();
                  this.router.navigate(["/paymentError"]);
                  // console.log("unknown error hit in if")
                  let error_message = "Unknown Error";
                  if (data.success.status.error_message !== null) {
                    error_message = data.success.status.error_message;
                    error_show = true;
                  }
                  if (data.success.status.api_error_message !== null) {
                    error_message = data.success.status.api_error_message;
                    error_show = true;
                  }
                  if (error_show) {
                    const last_payment_error =
                      localStorage.getItem("last_payment_error");
                    console.log("last_payment_error = " + last_payment_error);
                    console.log(
                      "data.success.status.id = " + data.success.status.id
                    );
                    if (last_payment_error != data.success.status.id) {
                      this.router.navigate(["/paymentError"]);
                    }
                  }
                }
              }
              // }, 5000);
            }
            if (this.hide) {
              setTimeout(() => {
                this.checkoutCart.checkChargeToken().subscribe(
                  (checks: any) => {
                    clearInterval(check);
                    // location.reload();
                    console.log(checks);
                    if (checks.error) {
                      console.log(checks.success);
                    }
                  },
                  (error: any) => {
                    // alert('error')
                    clearInterval(check);
                    // location.reload();
                    console.log(error);
                    if (this.router.url !== "/paymentError") {
                      this.router.navigate(["/error"]);
                    }
                    sessionStorage.removeItem("data");
                  }
                );
              }, 2 * 60 * 1000);
            }
            if (this.router.url !== "/checkout") {
              test.className = "";
              background.className = "";
              clearInterval(check);
              sessionStorage.removeItem("data");
            }
            if (data.success.status !== null) {
              if (
                data.success.status.transaction_id !== null &&
                data.success.status.api_error_message === null
              ) {
                this.showSpinner = false;
                console.log("Success");
                console.log(data.success.status);
                console.log(data.success.status.api_error_message);
                clearInterval(check);
                this.router.navigate(["/orderComplete"]);
                setTimeout(() => {
                  location.reload();
                  sessionStorage.removeItem("data");
                }, 2000);
              } else {
                // setTimeout(() => {
                if (!error_show) {
                  // location.reload();
                  let error_message = "Unknown Error";
                  if (data.success.status.error_message !== null) {
                    error_message = data.success.status.error_message;
                    error_show = true;
                  }
                  if (data.success.status.api_error_message !== null) {
                    error_message = data.success.status.api_error_message;
                    error_show = true;
                  }
                  if (error_show) {
                    const last_payment_error =
                      localStorage.getItem("last_payment_error");
                    if (last_payment_error != data.success.status.id) {
                      this.router.navigate(["/paymentError"]);
                    }
                  }
                }
                sessionStorage.removeItem("data");
                // }, 5000);
              }
            }
          }
        },
        (error: any) => {
          console.log(error);
          if (this.router.url !== "/paymentError") {
            this.router.navigate(["/error"]);
          }
          sessionStorage.removeItem("data");
        }
      );
    }, 1000);

    // setTimeout(() => {
    //   this.showSpinner = false;
    //   // this.showSuccess = true;
    // }, 2000);
  }
}
