import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { isDevMode } from '@angular/core';
import { local } from '../../../environments/local';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {

  myRecaptcha: boolean;
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  title: any;
  termId: any;
  term: any = localStorage.getItem('term');
  termName: any = localStorage.getItem('termName');
  id: any;
  images: any;
  name: any;
  recentImages: any;
  path: any;
  backdropsBy: any;
  categoryLink: any;
  recentName: any;
  p: number;
  pageSize = 102;


  constructor(private subCategory: DataService, private router: Router, private route: ActivatedRoute,private titleService:Title) { }

  ngOnInit() {


      this.path = local.imageUrl + '/storage';


    this.route.params.subscribe(params =>
      this.id = params['id']);

      this.route.params.subscribe(params =>
        this.termId = params['term_type_id']);

    this.route.params.subscribe(params =>
      this.backdropsBy = params['backdropsBy']);

    this.backdropsBy = this.backdropsBy.replace(/[_-]/g, ' ');

    localStorage.setItem('termName' , this.backdropsBy );
    localStorage.setItem('term' , this.termId);

    localStorage.setItem('categoryId', this.id);

    if (this.termId !== null) {
      localStorage.setItem('term_type_id' , this.termId);
      }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.showSpinner = true;

    switch (this.termId) {
      case '1': {
        this.title = 'Category';
        break;
      }
      case '2': {
        this.title = 'Show Theme';
        break;
      }
      default: {
        this.title = '';
        break;
      }
    }

    this.subCategory.subCategory(this.id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.showSpinner = false;
          this.images = data.success.images;
          this.name = data.success.category;
          this.titleService.setTitle(this.name +" - Backdrop Projections");
          this.categoryLink = data.success.links;
          for (let i = 0; i < this.categoryLink.length; i++) {
            this.categoryLink[i].name = this.categoryLink[i]['name'].replace(/[' ']/g, '-');
          }
          localStorage.setItem('categoryName', this.name);
          this.recentImages = JSON.parse(sessionStorage.getItem('recentImages'));
          this.recentName = JSON.parse(sessionStorage.getItem('recentImages'));
          for (let i = 0; i < this.recentImages.length; i++) {
            this.recentImages[i][0].name = this.recentImages[i][0]['name'].replace(/[' ']/g, '-');
          }
          console.log(this.recentImages);
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
        this.errorMessage = error.error.message;
        this.showError = true;
      }
    );
  }

  clearBreadcrumbSession()
  {
    sessionStorage.removeItem('breadCrumbImage');
  }


}
