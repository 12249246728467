import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { CartComponent } from "./components/cart/cart.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { ContactComponent } from "./components/contact/contact.component";
import { CreateBackdropComponent } from "./components/create-backdrop/create-backdrop.component";
import { DownloadAppComponent } from "./components/download-app/download-app.component";
import { LoginComponent } from "./components/login/login.component";
import { OrderCompleteComponent } from "./components/order-complete/order-complete.component";
import { SignupComponent } from "./components/signup/signup.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./components/home/home.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { SubcategoryComponent } from "./components/subcategory/subcategory.component";
import { ProductDetailsComponent } from "./components/product-details/product-details.component";
import { FavoritesComponent } from "./components/favorites/favorites.component";
import { SearchComponent } from "./components/search/search.component";
import { FaqComponent } from "./components/faq/faq.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { TermsConditionsComponent } from "./components/terms-conditions/terms-conditions.component";
import { SignupSuccessfulComponent } from "./components/signup-successful/signup-successful.component";
import { ContactThankYouComponent } from "./components/contact-thank-you/contact-thank-you.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ErrorComponent } from "./components/error/error.component";
import { PaymentErrorComponent } from "./components/payment-error/payment-error.component";
import { FormSubmittedComponent } from "./components/form-submitted/form-submitted.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { MaintenanceComponent } from "./components/maintenance/maintenance.component";
import { InstallationGuideComponent } from "./components/installation-guide/installation-guide.component";

const routes: Routes = [
  { path: "header", component: HeaderComponent },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SignupComponent },
  { path: "cart", component: CartComponent },
  { path: "categories", component: CategoriesComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "contact", component: ContactComponent },
  { path: "createBackdrop", component: CreateBackdropComponent },
  { path: "orderComplete", component: OrderCompleteComponent },
  { path: "downloadApp", component: DownloadAppComponent },
  { path: "", component: HomeComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "resetPassword/:data", component: ResetPasswordComponent },
  { path: "subCategory", component: SubcategoryComponent },
  { path: "productDetails", component: ProductDetailsComponent },
  { path: "favorites", component: FavoritesComponent },
  { path: "search", component: SearchComponent },
  { path: "faq", component: FaqComponent },
  { path: "aboutUs", component: AboutUsComponent },
  { path: "termsAndConditions", component: TermsConditionsComponent },
  { path: "signupSuccessful", component: SignupSuccessfulComponent },
  { path: "contactThankYou", component: ContactThankYouComponent },
  { path: "error", component: ErrorComponent },
  { path: "formSubmitted", component: FormSubmittedComponent },
  { path: "privacyPolicy", component: PrivacyPolicyComponent },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "InstallationGuide", component: InstallationGuideComponent },
  { path: "paymentError", component: PaymentErrorComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
