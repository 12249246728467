import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { isDevMode } from '@angular/core';
import { local } from '../../../environments/local';
import { PreviousRouteService } from '../../services/previous-route.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  cartItems: any;
  cartPackageItems: any;
  isLoggedIn: boolean;
  user_id: any = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');
  path: any;
  images: any;
  packageImages: any;
  subTotal: any;
  tax: any;
  total: any;
  showPackages: boolean;
  inCart: boolean;
  packageInCart: boolean;
  cartEmpty = false;
  showMessage: boolean;
  message: any;
  hasPromo: boolean;
  promobtn: boolean;
  discount: any;
  discountType: any;
  cartValues: any;

  constructor(private cart: DataService, private router: Router, private previousRouteService: PreviousRouteService, private titleService: Title) { }

  ngOnInit() {

    this.titleService.setTitle("Cart - Backdrop Projections");

    console.log(this.previousRouteService.getPreviousUrl());

    this.path = local.imageUrl + '/storage';

    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.isLoggedIn = true;
    }

    this.cart.getCartItems(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.cartItems = data.success.cartItems;
          this.cartPackageItems = data.success.cartPackageItems;

          this.images = data.success.images;
          this.packageImages = data.success.packageImages;

          this.subTotal = data.success.subTotal;
          this.tax = data.success.tax;
          this.total = data.success.total;
          sessionStorage.removeItem('promo_code');
          sessionStorage.removeItem('discount');
          sessionStorage.removeItem('promo_code_id');
          if (data.success.total === 0) {
            this.cartEmpty = true;
          }
          for (let i = 0; i < this.cartItems.length; i++) {
            this.cartItems[i].name = this.cartItems[i]['name'].replace(/-/g, " ");
          }
          for (let i = 0; i < this.cartPackageItems.length; i++) {
            this.cartPackageItems[i].name = this.cartPackageItems[i].name.replace(/-/g, " ");
          }
        }

      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );

    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.cart.countCart(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.cartValues = data.success.cartItems;
            console.log(this.cartValues);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    } else {
      this.cartValues = 0;
    }

  }

  public loadImage(event, item, name) {
    if (sessionStorage.getItem('cartImageUpdate')) {
      sessionStorage.removeItem('cartImageUpdate');
    }
    localStorage.setItem('term_type_id', '2');
    this.showPackages = false;
    name = name.replace(/[' ']/g, '-');
    localStorage.setItem('image_id', item);
    sessionStorage.setItem('breadCrumbImage', '');
    this.router.navigate(['/productDetails', { id: item, backdrop: name }]);
  }

  public loadPackage(event, item, name) {
    if (sessionStorage.getItem('cartImageUpdate')) {
      sessionStorage.removeItem('cartImageUpdate');
    }
    localStorage.setItem('term_type_id', 'showPackages');
    this.showPackages = true;
    name = name.replace(/[' ']/g, '-');
    localStorage.setItem('package_id', item);
    sessionStorage.setItem('breadCrumbImage', '');
    this.router.navigate(['/productDetails', { packageId: item, backdropPackage: name }]);
  }

  public updateImage(event, item, name) {
    localStorage.setItem('term_type_id', '2');
    this.showPackages = false;
    // localStorage.setItem('image_id', item);
    name = name.replace(/[' ']/g, '-');
    sessionStorage.setItem('cartImageUpdate', item);
    sessionStorage.setItem('breadCrumbImage', '');
    setTimeout(() => {
      this.router.navigate(['/productDetails', { id: item, backdrop: name }]);
    }, 500);

  }

  public updatePackage(event, item, name) {
    localStorage.setItem('term_type_id', 'showPackages');
    this.showPackages = false;
    // localStorage.setItem('package_id', item);
    name = name.replace(/[' ']/g, '-');
    sessionStorage.setItem('cartImageUpdate', item);
    sessionStorage.setItem('breadCrumbImage', '');
    setTimeout(() => {
      this.router.navigate(['/productDetails', { packageId: item, backdropPackage: name }]);
    }, 500);

  }

  removeFromCart($event, image_id) {
    this.cart.removeFromCart(image_id, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.inCart = false;
          sessionStorage.removeItem('promo_code');
          sessionStorage.removeItem('discount');
          sessionStorage.removeItem('promo_code_id');
          this.cartValues -= 1;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
    setTimeout(() => {
      this.cart.getCartItems(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.cartItems = data.success.cartItems;
            this.cartPackageItems = data.success.cartPackageItems;

            this.images = data.success.images;
            this.packageImages = data.success.packageImages;

            this.subTotal = data.success.subTotal;
            this.tax = data.success.tax;
            this.total = data.success.total;
            sessionStorage.removeItem('promo_code');
            sessionStorage.removeItem('discount');
            sessionStorage.removeItem('promo_code_id');
            if (data.success.total === 0) {
              this.cartEmpty = true;
            }
          }

        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }, 100);
  }

  removePackageFromCart($event, package_id) {
    this.cart.removePackageFromCart(package_id, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.packageInCart = false;
          sessionStorage.removeItem('promo_code');
          sessionStorage.removeItem('discount');
          sessionStorage.removeItem('promo_code_id');
          this.cartValues -= 1;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
    setTimeout(() => {
      this.cart.getCartItems(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.cartItems = data.success.cartItems;
            this.cartPackageItems = data.success.cartPackageItems;

            this.images = data.success.images;
            this.packageImages = data.success.packageImages;

            this.subTotal = data.success.subTotal;
            this.tax = data.success.tax;
            this.total = data.success.total;
            sessionStorage.removeItem('promo_code');
            sessionStorage.removeItem('discount');
            sessionStorage.removeItem('promo_code_id');
            if (data.success.total === 0) {
              this.cartEmpty = true;
            }
          }

        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }, 1000);
  }

  loadCheckout($event) {
    this.router.navigate(['/checkout']);
  }

  promoCode({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      console.log('not valid');
    } else {
      this.promobtn = true;
      this.cart.checkPromo(value['promo_code'], this.user_id).subscribe(
        (data: any) => {
          console.log(data);
          this.promobtn = true;
          if (data) {

            if (data.promo_code !== null) {
              this.discount = data.promo_code.discount_value;
              this.discountType = data.promo_code.discount_type;
              sessionStorage.setItem('promo_code', value['promo_code']);
              sessionStorage.setItem('promo_code_id',  data.promo_code.id);
              if (data.promo_code.discount_type === 1) {
                const discountAmount = this.total * this.discount / 100;
                this.total = this.total - discountAmount;
                sessionStorage.setItem('discount', String(discountAmount));
              } else {
                this.total = this.total - this.discount;
                sessionStorage.setItem('discount', this.discount);
              }
            } else {
              this.promobtn = false;
              this.hasPromo = false;
              sessionStorage.removeItem('promo_code');
              sessionStorage.removeItem('discount');
              sessionStorage.removeItem('promo_code_id');
            }
            this.showMessage = true;
            this.message = data.message;
            console.log(this.total);
            if (this.total < 0) {
              this.total = 0;
            }
            console.log(this.total);

            setTimeout(() => {
              this.showMessage = false;
            }, 5000);

          }

        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
          this.showMessage = true;

          setTimeout(() => {
            this.showMessage = false;
          }, 5000);
        }

      );
    }
  }
}
